<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('market.filters.title') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="status">{{ $t('market.table.fields.status') }}</label>
          <v-select
            id="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusTaskOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="user">{{ $t('market.table.fields.seller') }}</label>
          <v-select
            id="user"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userIdFilter"
            :options="userOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:userIdFilter', val)"
            @search="searchUser"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="project">{{ $t('market.table.fields.project') }}</label>
          <v-select
            id="project"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="projectIdFilter"
            :options="projectOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:projectIdFilter', val)"
            @search="searchProject"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    userIdFilter: {
      type: [Number, String, null],
      default: null,
    },
    projectIdFilter: {
      type: [Number, String, null],
      default: null,
    },
  },
  data() {
    return {
      userOptions: [],
      projectOptions: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/users')
      .then(response => {
        this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
      })
    await this.$http.get('/api/admin/projects')
      .then(response => {
        this.projectOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
  },
  methods: {
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
    async searchProject(query) {
      await this.$http.get('/api/admin/projects', {
        params: {
          query,
        },
      })
        .then(response => {
          this.projectOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
